<template>

<app-page theme="purple" close-button="Questions" :close-button-meta="{skipStep: true}" title="Note for Healthcare Professional" :size-content="true" class="select">

	<app-title text="Checking Inspiratory Flow" :is-uppercase="true" :hide-line="true" class="select-title" />

	<div class="info-text">
		
		<p>It is important to first assess and establish a person’s inspiratory flow in order to determine which type of inhaler they are physically able to inhale through.</p>

		<p>There are a range of devices available to help check someone’s inspiratory ability: AIM machine, Clip-Tone, Flo-Tone, In-Check DIAL inspiratory flow meter, placebo whistles.  Remember that the use of these tools in clinic settings may not always reflect the reality of daily use of their inhalers, so inspiratory effort should also be observed using the patient’s own inhalers. For example, whilst a person may inhale slowly and steadily through an inspiratory flow meter in clinic, they may still inhale quickly and deeply through their pMDI inhaler in error.</p>

		<p>When you have established the person’s inspiratory flow, the next step is to teach, check and optimise inhaler technique. </p>

		<p>When discussing inhaler choices with the person it is important to consider their:</p>

		<ul>
			<li>Lifestyle and circumstances.</li> 
			<li>Preference. </li> 
			<li>Age, competency, physical and cognitive ability. Comprehension of instructions, ability to adopt correct posture, ability to hold breath, physical dexterity and visual acuity must be considered, particularly in elderly patients and those with physical disabilities. </li> 
		</ul>

		<p class="reference gap">References</p>
		<p class="reference">Bryant T, et al.  Inhaler technique. Getting the basics right. Primary Care Respiratory Update. Vol 3 Issue 1. Spring 2016</p>
		<p class="reference">The Leeds Inhaler Device Guide: Inhaler Technique Instructions for Healthcare Professionals and Patients 1st Edition September 2018</p>
		<p class="reference">Usmani OS, Capstick T, Saleem A, Scullion J. Choosing an appropriate inhaler device for the treatment of adults with asthma or COPD. <a href="https://Guidelines.co.uk" target="_blank">Guidelines.co.uk</a> [accessed November 2020]</p>
		<p class="reference">The National Institute for Health and Care Excellence.  Chronic obstructive pulmonary disease in over 16s: diagnosis and management. NG115. 2018. <a href="https://www.nice.org.uk/guidance/ng115" target="_blank">https://www.nice.org.uk/guidance/ng115</a></p>
		<p class="reference">The National Institute for Health and Care Excellence.  Asthma: diagnosis, monitoring and chronic asthma management. NG80. 2017. <a href="https://www.nice.org.uk/guidance/ng80" target="_blank">https://www.nice.org.uk/guidance/ng80</a></p>

	</div>

</app-page>

</template>

<script>

export default {

}

</script>

<style scoped>

.select-title {
	width: 100%;
}

.info-text p {
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 20px;
	color: #fff;
}

.info-text ul {
	margin-bottom: 20px;
	list-style: disc;
	list-style-position: outside;
	margin-left: 18px;
}

.info-text li {
	font-size: 18px;
	line-height: 22px;
	color: #fff;
}

.info-text p.reference {
	margin-bottom: 10px;
	opacity: 0.7;
}

.info-text p.reference.gap {
	margin-top: 40px;
}

.info-text a {
	text-decoration: underline;
}

</style>
